/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "./styles/Home.css";
import { Link } from "react-router-dom";
import Logo from "./logo.webp";

import Analytics from "./analytic.webp";
import AppSpeed from "./app-speed.webp";
import CustomOneClick from "./customer-one-click.webp";
import MarkCusto from "./marketing-cus.webp";

import Loading from "../../Loading";
import { imageList } from "./imageList";

const Home = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let loadedImages = 0;

    const loadImage = (url) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        loadedImages++;
        if (loadedImages === imageList.length) {
          setIsLoading(false);
        }
      };
    };

    imageList.forEach(loadImage);
  }, []);

  if (isLoading) {
    return (
      <>
        <Loading />
      </>
    );
  }
  return (
    <div>
      {/* Navigation */}
      <nav className="navbar">
        <div className="container navbar-container">
          <Link to="/" className="HomePageLogo">
            <img src={Logo} alt="logo" />
            ServeSavvy
          </Link>
          <ul className="navbar-links">
            <li>
              <a href="#features" className="navbar-link">
                Features
              </a>
            </li>
            <li>
              <a href="#support" className="navbar-link">
                Support
              </a>
            </li>
            <li>
              <a href="#contact" className="navbar-link">
                Contact
              </a>
            </li>
            <li>
              <Link to={"/free-trial"} className="navbar-link btn btn-primary">
                Schedule a Call
              </Link>
            </li>
          </ul>
          <Link to={"/free-trial"} className="Schedule-call-mobile">
            Schedule a Call
          </Link>
        </div>
      </nav>

      {/* Hero Section */}
      <section className="hero">
        <div className="container">
          <div className="hero-content">
            <h1 className="hero-title">
              Run your Restaurant’s Custom Mobile App for free
            </h1>
            <p className="hero-description">
              We’re looking for early adopters to try our product and{" "}
              <strong>give us feedback</strong>
            </p>
            <a href="#contact" className="btn btn-primary">
              Get Started For Free
            </a>
          </div>
        </div>
      </section>

      {/* Lead Capture Section */}
      <section className="lead-capture" id="lead-capture">
        <div className="container">
          <h2 className="lead-capture-title">What you’ll get …</h2>
          <div className="lead-capture-features">
            <div className="feature-item">
              <p>
                <strong>A blazing-fast, native</strong> mobile app that delivers
                exceptional user experience and performance
              </p>
            </div>
            <div className="feature-item">
              <p>
                <strong>
                  Fully own your customer's data for long-lasting relationships
                </strong>{" "}
                with enhanced analytics via simple to navigate dashboards
              </p>
            </div>
            <div className="feature-item">
              <p>
                <strong>One-click coupons, discounts</strong> and many more
                tools to drive customer engagement and boost sales
              </p>
            </div>
            <div className="feature-item">
              <p>
                <strong>A complete free service (for now);</strong> no hidden
                fees, no surprises (and 100% transparent costs in the future if
                you are pleased and want to continue to work with us)
              </p>
            </div>
          </div>
          <div className="lead-buttons">
            <Link to={"/free-trial"} className="btn btn-primary">
              Schedule a Call
            </Link>
            <a href="#contact" className="btn btn-secondary">
              Contact Us
            </a>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="features" id="features">
        <div className="container">
          <h2 className="section-title features-title">Unique App Features</h2>
          <div className="features-container">
            <div className="feature-row">
              <div className="feature-content">
                <h3 className="feature-title">
                  An application built and optimized for speed
                </h3>
                <p className="feature-description">
                  Loved by Google, appreciated by users, and designed to give
                  everyone a seamless experience.
                </p>
              </div>
              <div className="feature-image">
                <img src={AppSpeed} alt="App Speed" />
              </div>
            </div>
            <div className="feature-row">
              <div className="feature-content">
                <h3 className="feature-title">
                  Effortless marketing at your fingertips
                </h3>
                <p className="feature-description">
                  Send coupons, exclusive discounts, and personalized
                  notifications to engage customers{" "}
                  <strong>in just a few clicks.</strong>
                </p>
              </div>
              <div className="feature-image">
                <img src={MarkCusto} alt="Marketing" />
              </div>
            </div>
            <div className="feature-row">
              <div className="feature-content">
                <h3 className="feature-title">
                  Empower your customers with control
                </h3>
                <p className="feature-description">
                  Let them track orders in real time, access their order
                  history, reject unapproved orders within 5 minutes, or delete
                  their accounts instantly.
                </p>
              </div>
              <div className="feature-image">
                <img src={CustomOneClick} alt="Empower your customers" />
              </div>
            </div>
            <div className="feature-row">
              <div className="feature-content">
                <h3 className="feature-title">
                  A powerhouse back-end, simplified
                </h3>
                <p className="feature-description">
                  Manage payments, roles, analytics, menus, and
                  more—effortlessly, from an intuitive dashboard.
                </p>
              </div>
              <div className="feature-image">
                <img src={Analytics} alt="A powerhouse back-end" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Support Section */}
      <section className="support" id="support">
        <div className="container">
          <h2 className="section-title support-title">We're here to help</h2>
          <p className="section-description support-description">
            At the end of the day, we're looking for early-adopters to learn and
            better assist with their challenges and goals.
          </p>
          <div className="support-items">
            <div className="support-item">
              <div className="support-item-icon">🚀</div>
              <h3 className="support-item-title">Quick Setup</h3>
              <p className="support-item-description">
                Get your custom mobile app up and running in days, not months.
                Our streamlined development process ensures a fast deployment
                without sacrificing quality or functionality.
              </p>
            </div>
            <div className="support-item">
              <div className="support-item-icon">🎨</div>
              <h3 className="support-item-title">User-Friendly Interface</h3>
              <p className="support-item-description">
                Intuitive design that your customers will love. We focus on
                creating simple, beautiful interfaces that make navigation easy
                and enhance the overall user experience of your app.
              </p>
            </div>
            <div className="support-item">
              <div className="support-item-icon">🛠️</div>
              <h3 className="support-item-title">Quick Support</h3>
              <p className="support-item-description">
                Encounter an issue or have a question? Our dedicated support
                team is ready to assist you with any challenges. We pride
                ourselves on responding to all inquiries in less than a day.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Form Section */}
      <section className="contact" id="contact">
        <div className="container">
          <div className="contact-container">
            <div className="contact-info">
              <h2 className="contact-title">Get in Touch</h2>
              <p className="contact-description">
                Have questions or ready to get started? Reach out to us and
                we'll get back to you promptly.
              </p>
              <div className="contact-details">
                <div className="contact-detail">
                  <div className="contact-detail-icon">📧</div>
                  <div className="contact-detail-text">
                    servesavvy.info@gmail.com
                  </div>
                </div>
                {/* <div className="contact-detail">
                  <div className="contact-detail-icon">📞</div>
                  <div className="contact-detail-text">+1 (555) 123-4567</div>
                </div> */}
                <div className="contact-detail">
                  <div className="contact-detail-icon">📍</div>
                  <div className="contact-detail-text">Greater London, UK</div>
                </div>
              </div>
            </div>
            <div className="contact-form">
              <form
                id="contactForm"
                method="POST"
                name="MessageUs"
                data-netlify="true"
                onSubmit="submit"
              >
                {/* netlify-form -- START -- */}
                <input type="hidden" name="form-name" value="MessageUs" />
                <div hidden>
                  <label>First Name:</label>
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                  />
                </div>
                <div hidden>
                  <label>Last Name:</label>
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                  />
                </div>
                <div hidden>
                  <label>email:</label>
                  <input type="text" name="email" value={formData.email} />
                </div>
                <div hidden>
                  <label>phone:</label>
                  <input type="tel" name="phone" value={formData.phone} />
                </div>
                <div hidden>
                  <label>Message:</label>
                  <textarea
                    type="text"
                    name="message"
                    value={formData.message}
                  />
                </div>

                {/* netlify-form -- END -- */}
                <div className="form-group">
                  <label htmlFor="firstName" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    className="form-control"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    placeholder="Enter your first name"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="lastName" className="form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Enter your last name"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Enter your email address"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email" className="form-label">
                    Phone
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Enter your phone number"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="message" className="form-label">
                    Your Question
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Tell us what you need help with..."
                    required
                  ></textarea>
                </div>
                <button type="submit" className="submit-btn">
                  Send Message
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="footer">
        <div className="container">
          <div className="footer-container">
            <div className="footer-column">
              <a href="#" className="footer-logo">
                ServeSavvy
              </a>
              <p className="footer-description">
                Creating custom mobile experiences that drive growth and
                engagement for businesses of all sizes.
              </p>
              {/* <div className="footer-social">
                <a href="#" className="social-icon">
                  f
                </a>
                <a href="#" className="social-icon">
                  t
                </a>
                <a href="#" className="social-icon">
                  in
                </a>
                <a href="#" className="social-icon">
                  ig
                </a>
              </div> */}
            </div>
            {/* <div className="footer-column">
              <h3 className="footer-title">Company</h3>
              <ul className="footer-links">
                <li className="footer-link">
                  <a href="#">About Us</a>
                </li>
                <li className="footer-link">
                  <a href="#">Our Team</a>
                </li>
                <li className="footer-link">
                  <a href="#">Careers</a>
                </li>
                <li className="footer-link">
                  <a href="#">Blog</a>
                </li>
              </ul>
            </div> */}
            <div className="footer-column">
              <h3 className="footer-title">Services</h3>
              <ul className="footer-links">
                <li className="footer-link">
                  <a href="#">App Development</a>
                </li>
                <li className="footer-link">
                  <a href="#">UI/UX Design</a>
                </li>
                <li className="footer-link">
                  <a href="#">Marketing Tools</a>
                </li>
                <li className="footer-link">
                  <a href="#">Support & Maintenance</a>
                </li>
              </ul>
            </div>
            <div className="footer-column">
              <h3 className="footer-title">Resources</h3>
              <ul className="footer-links">
                <li className="footer-link">
                  <a
                    href="https://reactnative.dev/docs/getting-started"
                    target="_blank" // Opens the link in a new tab
                    rel="noopener noreferrer" // Security measure for links opening in a new tab
                    aria-label="Industry Insights - Read more about hospitality industry trends"
                  >
                    Documentation
                  </a>
                </li>
                {/* <li className="footer-link">
                  <a href="#">FAQs</a>
                </li> */}
                <li className="footer-link">
                  <a
                    href="https://www.ons.gov.uk/employmentandlabourmarket/peopleinwork/employmentandemployeetypes/articles/hospitalitybusinessesaremostlikelytobestrugglingtofillvacancies/2021-09-16/relateddata"
                    target="_blank" // Opens the link in a new tab
                    rel="noopener noreferrer" // Security measure for links opening in a new tab
                    aria-label="Industry Insights - Read more about hospitality industry trends" // Accessible label for screen readers
                  >
                    Case Studies
                  </a>
                </li>
                <li>
                  <a href="/terms-and-conditions">Terms of Service</a>
                </li>
                <li>
                  <a href="/privacy">Privacy Policy</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="copyright">
            &copy; 2025 ServeSavvy. All rights reserved.
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;
