import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ErrorPage from "./Pages/ErrorPage/ErrorPage";

import "./App.css";
import Home from "./Pages/Home/Home";
// import Features from "./Pages/Features/Features";
// import Pricing from "./Pages/Pricing/Pricing";
// import RestaurantProfitBoost from "./Pages/Pricing/Articles/RestaurantProfitBoost";
// import RestaurantTransformation from "./Pages/Pricing/Articles/RestaurantTransformation";
// import RestaurantAppBenefits from "./Pages/Pricing/Articles/RestaurantAppBenefits-and-Real-Life-Cases";
// import RestaurantAppBenefitsList from "./Pages/Pricing/Articles/RestaurantAppBenefitsList";
// import AboutUs from "./Pages/AboutUs/AboutUs";
// import ContactUs from "./Pages/ContactUs/ContactUs";
// import ScheduleDemo from "./Pages/ScheduleDemo/ScheduleDemo";
import FreeTrial from "./Pages/FreeTrial/FreeTrial";
import Terms from "./Pages/Legal/Terms";
import Privacy from "./Pages/Legal/Privacy";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/features" element={<Features />} /> */}
        {/* <Route path="/pricing" element={<Pricing />} /> */}
        {/* <Route
          path="/Key-to-Increasing-Restaurant-Profits"
          element={<RestaurantProfitBoost />}
        />
        <Route
          path="/Transformed-the-Restaurant-and-Food-Delivery-Industry"
          element={<RestaurantTransformation />}
        />
        <Route
          path="/Benefits-and-Real-Life-Cases"
          element={<RestaurantAppBenefits />}
        />
        <Route
          path="/25-Benefits-of-Having-a-Mobile-Application-for-a-Restaurant"
          element={<RestaurantAppBenefitsList />}
        /> */}
        {/* <Route path="/about-us" element={<AboutUs />} /> */}
        {/* <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/schedule-demo" element={<ScheduleDemo />} />*/}
        <Route path="/free-trial" element={<FreeTrial />} />
        <Route path="/terms-and-conditions" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}

export default App;
