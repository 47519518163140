import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Navigation.css";
import "./NavBar.css";
import Logo from "./logo.webp";

const Navigation = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <nav className="Navigation">
      <Link to="/" className="logo">
        <img src={Logo} alt="logo" />
        ServeSavvy
      </Link>

      {/* <div className="fullNavBar">
        <ul className="NavigationTitles">
          <li className={location.pathname === "/" ? "active" : ""}>
            <Link to="/">Home</Link>
          </li>
          <li className={location.pathname === "/features" ? "active" : ""}>
            <Link to="/features">Features</Link>
          </li>
          <li className={location.pathname === "/pricing" ? "active" : ""}>
            <Link to="/pricing">Pricing</Link>
          </li>
          <li className={location.pathname === "/about-us" ? "active" : ""}>
            <Link to="/about-us">About Us</Link>
          </li>
          <li className={location.pathname === "/contact-us" ? "active" : ""}>
            <Link to="/contact-us">Contact Us</Link>
          </li>
          <li className="ctaButton">
            <Link to="/schedule-demo">Schedule a Demo </Link>
          </li>
        </ul>
      </div> */}
      {/* <div className="side-nav">
        <button
          className={`menu-button ${isOpen ? "open XbtnPosition" : ""}`}
          aria-label={isOpen ? "Close Menu" : "Open Menu"}
          onClick={() => {
            toggleMenu();
          }}
        >
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </button>
        <div className={`menu-container ${isOpen ? "open" : ""}`}>
          <ul className="NavigationTitles">
            <li className={location.pathname === "/" ? "active" : ""}>
              <Link to="/">Home</Link>
            </li>
            <li className={location.pathname === "/features" ? "active" : ""}>
              <Link to="/features">Features</Link>
            </li>
            <li className={location.pathname === "/pricing" ? "active" : ""}>
              <Link to="/pricing">Pricing</Link>
            </li>
            <li className={location.pathname === "/about-us" ? "active" : ""}>
              <Link to="/about-us">About Us</Link>
            </li>
            <li className={location.pathname === "/contact-us" ? "active" : ""}>
              <Link to="/contact-us">Contact Us</Link>
            </li>
            <li className="ctaButton">
              <Link to="/schedule-demo">Schedule a Demo </Link>
            </li>
          </ul>
          <Link to="/" className="sideNavLogo">
            <img src={Logo} alt="logo" />
            ServeSavvy
          </Link>
        </div>
      </div> */}
    </nav>
  );
};

export default Navigation;
