// images.js
export const imageList = [
  require("./analytic.webp"),
  require("./app-speed.webp"),
  require("./customer-one-click.webp"),
  require("./logo.webp"),
  require("./marketing-cus.webp"),

  // ... add more images
];
